/* eslint-disable consistent-return */
import { Grid, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { mainBlack, mainGrey, SMALL_RADIUS } from 'components/styled/Theme'
import Option from 'components/Option'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import HomeAction from 'pages/home/actions/HomeAction'
import FollowAction from './actions/FollowAction'
import LittleMap from '../components/LittleMap'
import EventsCard from '../components/cards/EventsCard'
import { concat, groupBy, isNil, isUndefined, keys, maxBy, round, sortBy, uniq, uniqBy } from 'lodash'
import { filterObsLinkedStations, getMarkerByStationType, getStationTypeCodeFromType } from 'utils/StationUtils'
import useStateProgress from 'utils/customHook/useStateProgress'
import ProgressBar from 'components/progress/ProgressBar'
import { chunkWithWords, getSandreLabel } from 'utils/StringUtil'
import { SANDRE } from '../referencials/constants/ReferencialConstants'
import { CHART_SELECTED_TIME, OPERATOR_TYPE_NAME, OWNER_TYPE_NAME } from 'pages/home/constants/HomeConstants'
import { hasValue } from 'utils/NumberUtil'
import { HomeActionConstant } from 'pages/home/reducers/HomeReducer'
import { getDate, getDateWithHour, getDayDiff, getFullDate } from 'utils/DateUtil'
import MultiChart from '../components/echart/MultiChart'
import { getColorFromPalette2, getColorFromPalettePluvio, getEventColor, getRGBColor, getTextColorByBackground } from 'utils/ColorUtil'
import { statusIcon } from 'utils/StatusUtil'
import DtoPiezometryStationMeasure from '../referencials/dto/DtoPiezometryStationMeasure'
import DtoEvent from './dto/DtoEvent'
import DtoMeasureStats from './dto/DtoMeasureStats'
import moment from 'moment'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { getBarWidth, setYOptions, setYOptionsPiezo, yAutomaticScaleValues } from '../components/echart/EChartUtils'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import SituationHydrobioPanel from '../components/echart/SituationHydrobioPanel'
import SimpleMultiAutocomplete from 'components/SimpleMultiAutocomplete'
import DtoQualityThreshold from './dto/DtoQualityThreshold'
import useAbortController from 'utils/customHook/useAbortController'
import useBoolean from 'utils/customHook/useBoolean'
import { calculateThresholdResult } from 'utils/AnalyseUtils'
import { ParameterGraph } from '../../../quality/components/ParameterGraph'
import { push } from 'connected-react-router'
import ChartTabs from '../components/echart/ChartTabs'
import { HISTO, J365 } from '../components/echart/ChartFollowContants'
import ResourcesAction from '../resources/actions/ResourcesAction'
import SmallPicturePanel from '../components/SmallPicturePanel'
import FilePanel from '../components/FilePanel'
import DtoObservatoryFollowResult from './dto/DtoObservatoryFollowResult'
import DtoAssociatedStation from 'pages/home/dto/DtoAssociatedStation'
import { authorizeExport, componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_HYDRO_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE, H_PRODUCTION_MODULE, H_QUALITO_MODULE, H_RESOURCE_MODULE } from 'pages/home/constants/AccessRulesConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import InstallationLinkedProduction from './components/InstallationLinkedProduction'
import DtoAnalysisLight from './dto/DtoAnalysisLight'

import DtoPiezometerChartOptions from './dto/DtoPiezometerChartOptions'
import DtoPiezometer from 'pages/home/dto/DtoPiezometer'
import DtoHydrometricStation from 'pages/home/dto/DtoHydrometricStation'
import PluviometerDto from 'pages/home/dto/PluviometerDto'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import { DISPLAY_COTE, MEASURE_COTE, PIEZO_TAB_DISPLAY_MODES } from '../components/echart/ChartConstant'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import { HYDRO, PIEZO, PLUVIO } from 'pages/home/constants/HabilitationConstants'
import useApplicationSetting, { intParser } from 'utils/customHook/useApplicationSetting'
import { chartGrey } from 'components/styled/Theme'

const CHRONIC_TYPE = -1
const SAMPLE_TYPE = -2
const HYDROMETER_HEIGHT_TYPE = 4
const ROUND_VALUE = 3
const DATA_TYPE = {
    VIRTUAL_EFFECTIVE_RAIN: -1,
    RAIN: 1,
    EFFECTIVERAIN: 2,
    ETP: 3,
    VALUE_1: 4,
    VALUE_2: 5,
    VALUE_3: 6,
}
const PLUVIO_GROUPED = 'SUM_AUTO'
const SOIL_FOOT_PIEZOMETER = 3
const ALL = 'all'
const GROUPS_FUNC = {
    RAW: 'RAW',
    MAX: 'MAX',
    MIN: 'MIN',
    AVERAGE: 'AVERAGE',
}

const PiezometerChart = ({
    station,
    stats = [],
    stationsEvents = [],
    measures = [],
    stationThresholds = [],
    typesIdWithSeveralDataPerDay = [],
    displayCote = 0,

    piezometryOptions,

    graphicHeight = 250,
    onFullScreen = () => {},
    defaultMinDate,
    defaultMaxDate,
}) => {
    const {
        piezometryDataTypes,
    } = useSelector(store => ({
        piezometryDataTypes: store.FollowReducer.piezometryDataTypes,
    }), shallowEqual)

    const stationName = station.name
    const stationCode = station.code

    const events = stationsEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            return e.graph === '1'
        }
        return false
    })

    const getBarDataEvents = () => events.map(e => ({
        date: e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(),
        value: 1,
        color: getRGBColor(getEventColor(e.eventType)),
        event: e,
    }))

    const eventsFormatted = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        gridName: i18n.events,
        dataList: getBarDataEvents(),
        type: 'bar',
        barWidth: '10px',
    }], [])

    const groupedMeasures = useMemo(() => {
        const measuresFiltered = measures.filter(m => !!m.measures.length)
        return groupBy(measuresFiltered, 'dataType')
    }, [measures])
    const groupedMeasuresKeys = useMemo(() => {
        const keysGroupedMeasures = keys(groupedMeasures)
        const chronicleTypeIds = piezometryDataTypes.filter(pdt => pdt.isPiezo).map(pdt => pdt.id)
        const chronicleKeys = keysGroupedMeasures.filter(k => k === `${CHRONIC_TYPE}` || chronicleTypeIds.includes(parseInt(k)))
        const chronicleKey = chronicleKeys.length ? [`${CHRONIC_TYPE}`] : []
        return [
            ...chronicleKey,
            ...keysGroupedMeasures.filter(k => k === `${SAMPLE_TYPE}`),
            ...keysGroupedMeasures.filter(k => k !== `${CHRONIC_TYPE}` && k !== `${SAMPLE_TYPE}` && !chronicleTypeIds.includes(parseInt(k))).sort((a, b) => parseInt(a) - parseInt(b)),
            ...keysGroupedMeasures.filter(k => k !== `${CHRONIC_TYPE}` && k !== `${SAMPLE_TYPE}` && chronicleTypeIds.includes(parseInt(k))).sort((a, b) => parseInt(a) - parseInt(b)),
        ].filter(k => !!k && k !== 'undefined')
    }, [groupedMeasures])

    const getFilteredData = (data) => data.flatMap((m, i) => {
        const diffInDays = (m?.date && data[i + 1]?.date) ? getDayDiff(data[i + 1].date, m.date) : 0
        return (m.initialPoint !== 1 && diffInDays <= 31 && !isNil(m?.date)) ? [m] : [
            { ...m, value: undefined, date: moment(m.date).subtract(1, 'second').valueOf() },
            { ...m, marker: 'emptyCircle' },
        ]
    })

    const getGroupColor = (groupFunc, defautColor) => {
        switch (groupFunc) {
            case GROUPS_FUNC.RAW:
                return defautColor
            case GROUPS_FUNC.MAX:
                return 'blue'
            case GROUPS_FUNC.MIN:
                return 'red'
            case GROUPS_FUNC.AVERAGE:
                return 'green'
            default:
                return defautColor
        }
    }

    const getGroupLabel = groupFunc => {
        switch (groupFunc) {
            case GROUPS_FUNC.RAW:
                return i18n.gross
            case GROUPS_FUNC.MAX:
                return i18n.max
            case GROUPS_FUNC.MIN:
                return i18n.min
            case GROUPS_FUNC.AVERAGE:
                return i18n.average
            default:
                return ''
        }
    }

    const measuresFormatted = useMemo(() => groupedMeasuresKeys.flatMap((key, index) => {
        const values = groupedMeasures[key] || []

        return values.map(value => {
            const stat = stats.find(h => h.typeId === value.dataType)
            const dataType = piezometryDataTypes.find(pdt => pdt.id === value.dataType) || {}
            const unit = stat?.unit ? `${stat?.unit} ` : ''

            const severalData = typesIdWithSeveralDataPerDay.find(t => t.typeId === value.dataType)

            const defaultColor = stat?.color || (dataType.isPiezo ? 'red' : chartGrey)
            const color = ((dataType.isPiezo || severalData?.typeId === CHRONIC_TYPE) && severalData?.hasSeveralDataPerDay) ? (value.groupFunc === GROUPS_FUNC.MAX ? 'blue' : (value.groupFunc === GROUPS_FUNC.MIN ? 'red' : defaultColor)) : defaultColor
            const groupColor = keys(GROUPS_FUNC).includes(value.groupFunc) ? getGroupColor(value.groupFunc, color) : color

            const defaultName = value.dataType === CHRONIC_TYPE ? i18n.depths : stat.label || ''
            const additionalName = (severalData?.hasSeveralDataPerDay || value.groupFunc !== ALL) ? `${defaultName} ${getGroupLabel(value.groupFunc)}` : defaultName
            return {
                gridIndex: dataType.isPiezo ? 1 : index + 1,
                name: additionalName,
                dataType: value.dataType,
                unit,
                color: groupColor,
                gridName: stat?.label || key,
                dataList: getFilteredData(value.measures),
                type: value.dataType === SAMPLE_TYPE ? 'bar' : 'line',
                connectNulls: false,
                barWidth: value.dataType === SAMPLE_TYPE ? getBarWidth(value.measures.length) : undefined,
            }
        })
    }), [groupedMeasures, groupedMeasuresKeys, stationName, stationCode, stats])

    const dataFormatted = [...eventsFormatted, ...measuresFormatted]

    const thresholds = stats.length ? uniqBy(stationThresholds.filter(t => groupedMeasuresKeys.includes(`${t.dataType}`)), 'id').map((t, index) => {
        const stat = stats.find(h => h.typeId === parseInt(t.dataType))
        const unit = stat?.unit ? `${stat?.unit} ` : ''
        return {
            ...t,
            unit,
            gridName: stat?.label || t.dataType,
            gridIndex: measuresFormatted.find(mf => `${mf.dataType}` === t.dataType)?.gridIndex || (index + 1),
        }
    }) : []

    const eventGrids = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        yOptions: {
            type: 'value',
            nameRotate: 0,
            nameGap: 20,
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        xOptions: {
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        },
        gridOptions: {
            top: 40,
            left: '100px',
            height: 40,
        },
    }], [])

    const getPiezometerOption = type => {
        if (type == 0) {
            return piezometryOptions?.find(opt => !opt.dataType || parseInt(opt.dataType) <= 0) || {}
        }
        return piezometryOptions?.find(opt => type == opt.dataType) || {}
    }

    const calculateLastLandmarks = () => {
        const lastLandmark = maxBy(station.link_landmarks, 'startDate')
        const lastRefAlti = lastLandmark ? station.link_altimetrySystems?.find(alt => alt.natureCode === lastLandmark.altimetrySystemNature && alt.startDate === lastLandmark.altimetrySystemDate) : null

        const groundRefALtis = station.link_altimetrySystems?.filter(alt => alt.natureCode == SOIL_FOOT_PIEZOMETER)
        const groundRefAlti = groundRefALtis?.length ? maxBy(groundRefALtis, 'startDate') : null
        return { lastLandmark: lastLandmark && lastRefAlti ? lastLandmark.height + lastRefAlti.altitude : null, groundRefAlti: groundRefAlti && groundRefAlti.altitude }
    }

    const measuresGrids = useMemo(() => measuresFormatted.length ? groupedMeasuresKeys.filter(g => parseInt(g) === CHRONIC_TYPE || !piezometryDataTypes.find(pdt => pdt.id === parseInt(g))?.isPiezo).map((key, index) => {
        const dataType = groupedMeasures[key]?.[0]?.dataType
        const stat = stats.find(h => h.typeId === dataType)
        const unit = stat?.unit ? `[${stat?.unit}] ` : ''

        const option = getPiezometerOption(dataType)
        const { lastLandmark, groundRefAlti } = calculateLastLandmarks()
        return {
            gridIndex: index + 1,
            name: `${key === `${CHRONIC_TYPE}` ? i18n.depths : stat?.label || key} ${unit}`,
            dataType,
            gridOptions: {
                left: '100px',
                top: 35,
                height: graphicHeight,
            },
            yOptions: {
                inverse: (dataType === CHRONIC_TYPE && displayCote !== MEASURE_COTE.NGF),
                calculateY: true,
                ...setYOptionsPiezo(
                    option,
                    dataType,
                    yAutomaticScaleValues(concat(
                        measuresFormatted.filter(m => m.gridIndex === (index + 1)).flatMap(m => m.dataList.map(d => d.value)),
                        thresholds.filter(m => m.gridIndex === (index + 1)).map(({ value }) => value),
                    )),
                    (dataType === CHRONIC_TYPE ? 2 : 0),
                    lastLandmark,
                    groundRefAlti,
                ),
            },
        }
    }) : [], [graphicHeight, groupedMeasures, groupedMeasuresKeys, measuresFormatted, stats, piezometryOptions])

    const grids = [...eventGrids, ...measuresGrids]

    const formatTooltip = params => {
        if (keys(params[0].value[2]?.event)?.length) {
            return getFullDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [,, { event }] }) => {
                return `<br/>${marker} ${seriesName}: ${event.comment ? (chunkWithWords(event.comment, 40).replaceAll('\n', '<br />')) : ''}<br />
                ${hasValue(event.ns) ? `${i18n.staticLevelMeasure} : ${event.ns}m<br />` : ''}
                ${hasValue(event.nc) ? `${i18n.sensorInstantLevel} : ${event.nc}m<br />` : ''}`
            }).join('')
        }
        return getFullDate(params[0].axisValue) + params.filter(({ value: [, result] }) => !isNil(result)).map(({ marker, seriesName, value: [, result, statusObj], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, ROUND_VALUE)} ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`).join('')
    }

    return (
        <MultiChart
            data={dataFormatted}
            grids={grids}
            stationsEvents={stationsEvents}
            thresholds={thresholds}
            roundValue={ROUND_VALUE}
            headerHeight={20}
            footerHeight={70}
            exportName={stationName || stationCode || ''}
            withDataZoom
            withFullScreen
            withOtherLegend
            withYZoom
            withToolLine
            withToolMarker
            onFullScreen={onFullScreen}
            defaultDisplayMarker={false}
            tooltipFormatter={formatTooltip}
            defaultMinDate={defaultMinDate}
            defaultMaxDate={defaultMaxDate}
            withExport={authorizeExport(PIEZO)}
        />
    )
}

PiezometerChart.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            code: PropTypes.string,
        }),
    ]),
    stats: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    piezometryOptions: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerChartOptions)),
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    typesIdWithSeveralDataPerDay: PropTypes.arrayOf(PropTypes.shape({
        hasSeveralDataPerDay: PropTypes.bool,
        typeId: PropTypes.number,
    })),
    displayCote: PropTypes.number,
    stationThresholds: PropTypes.arrayOf(PropTypes.shape({})),
    graphicHeight: PropTypes.number,
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
}

const HydrometerChart = ({
    station: { id: stationId, name: stationName, code: stationCode },
    stats = [],
    stationsEvents = [],
    measures = [],
    stationThresholds = [],

    graphicHeight = 250,
    onFullScreen = () => {},
    defaultMinDate,
    defaultMaxDate,
}) => {
    const dataTypesId = useMemo(() => stats.map(stat => stat.typeId), [stats])

    const thresholds = stats.length ? stationThresholds.filter(t => parseInt(t.stationId) === stationId && dataTypesId.includes(parseInt(t.dataType))).map(t => {
        const stat = stats.find(h => h.typeId === parseInt(t.dataType))
        const unit = stat?.unit ? `${stat?.unit}]` : ''
        return {
            ...t,
            unit,
            gridName: stat?.label || t.dataType,
        }
    }) : []

    const events = stationsEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            return e.graph === '1'
        }
        return false
    })

    const getBarDataEvents = () => events.map(e => ({
        date: e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(),
        value: 1,
        color: getRGBColor(getEventColor(e.eventType)),
        event: e,
    }))

    const eventsFormatted = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        gridName: i18n.events,
        dataList: getBarDataEvents(),
        type: 'bar',
        barWidth: '10px',
    }], [])

    const groupedMeasures = useMemo(() => {
        const measuresFiltered = measures.filter(m => !!m.measures.length)
        return groupBy(measuresFiltered, 'dataType')
    }, [measures])
    const groupedMeasuresKeys = useMemo(() => [...keys(groupedMeasures).filter(k => k === `${HYDROMETER_HEIGHT_TYPE}`), ...keys(groupedMeasures).filter(k => k !== `${HYDROMETER_HEIGHT_TYPE}`).sort((a, b) => parseInt(a) - parseInt(b))], [groupedMeasures])

    const getFilteredData = (data) => data.filter((m, i) => {
        const diffInDays = (m?.date && m[i + 1]?.date) ? getDayDiff(m.date, m[i + 1].date) : 0
        return (m.initialPoint !== 1 && diffInDays <= 31 && !isNil(m?.date)) ? [m] : [
            { ...m, value: undefined, date: moment(m.date).subtract(1, 'second').valueOf() },
            { ...m, marker: 'emptyCircle' },
        ]
    })

    const measuresFormatted = useMemo(() => groupedMeasuresKeys.flatMap((key, index) => {
        const values = groupedMeasures[key]
        return values.map(value => {
            const stat = stats.find(h => h.typeId === value.dataType)
            const unit = stat?.unit ? `${stat?.unit} ` : ''
            return {
                gridIndex: index + 1,
                name: stat.label || '',
                unit,
                color: parseInt(value.dataType) === HYDROMETER_HEIGHT_TYPE ? 'blue' : getColorFromPalette2(index),
                gridName: stat?.label || key,
                dataList: getFilteredData(value.measures),
            }
        })
    }), [groupedMeasures, groupedMeasuresKeys, stationName, stationCode, stats])

    const dataFormatted = [...eventsFormatted, ...measuresFormatted]

    const eventGrids = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        yOptions: {
            type: 'value',
            nameRotate: 0,
            nameGap: 20,
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        xOptions: {
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        },
        gridOptions: {
            top: 25,
            left: '100px',
            height: 40,
        },
    }], [])

    const measuresGrids = useMemo(() => measuresFormatted.length ? groupedMeasuresKeys.map((key, index) => {
        const dataType = groupedMeasures[key]?.[0]?.dataType
        const stat = stats.find(h => h.typeId === dataType)
        const unit = stat?.unit ? `[${stat?.unit}] ` : ''
        return {
            gridIndex: index + 1,
            name: `${stat?.label || key} ${unit}`,
            dataType,
            gridOptions: {
                left: '100px',
                top: 35,
                height: graphicHeight,
            },
            yOptions: {
                ...setYOptions(null, yAutomaticScaleValues(concat(thresholds.filter(t => t.dataType === `${dataType}`).map(t => t.value), measuresFormatted[index].dataList.map(d => d.value)), 3)),
            },
        }
    }) : [], [graphicHeight, groupedMeasures, groupedMeasuresKeys, measuresFormatted, stats])

    const grids = [...eventGrids, ...measuresGrids]

    const formatTooltip = params => {
        if (keys(params[0].value[2]?.event)?.length) {
            return getFullDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [,, { event }] }) => {
                return `<br/>${marker} ${seriesName}: ${event.comment ? (chunkWithWords(event.comment, 40).replaceAll('\n', '<br />')) : ''}<br />
                ${hasValue(event.ns) ? `${i18n.staticLevelMeasure} : ${event.ns}m<br />` : ''}
                ${hasValue(event.nc) ? `${i18n.sensorInstantLevel} : ${event.nc}m<br />` : ''}`
            }).join('')
        }
        return getFullDate(params[0].axisValue) + params.filter(({ value: [, result] }) => !isNil(result)).map(({ marker, seriesName, value: [, result, statusObj], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, ROUND_VALUE)} ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`).join('')
    }

    return (
        <MultiChart
            data={dataFormatted}
            grids={grids}
            stationsEvents={stationsEvents}
            thresholds={thresholds}
            roundValue={ROUND_VALUE}
            footerHeight={70}
            exportName={stationName || stationCode || ''}
            withDataZoom
            withOtherLegend
            withYZoom
            withToolLine
            withToolMarker
            onFullScreen={onFullScreen}
            defaultDisplayMarker={false}
            tooltipFormatter={formatTooltip}
            defaultMinDate={defaultMinDate}
            defaultMaxDate={defaultMaxDate}
            withExport={authorizeExport(HYDRO)}
        />
    )
}

HydrometerChart.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            code: PropTypes.string,
        }),
    ]),
    stats: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    stationThresholds: PropTypes.array,
    graphicHeight: PropTypes.number,
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
}

const PluviometerChart = ({
    station: { id: stationId, name: stationName, code: stationCode },
    stats = [],
    stationsEvents = [],
    measures = [],
    stationThresholds = [],

    graphicHeight = 250,
    onFullScreen = () => {},
    defaultMinDate,
    defaultMaxDate,
}) => {
    const dataTypesId = useMemo(() => stats.map(stat => stat.typeId), [stats])

    const thresholds = stats.length ? stationThresholds.filter(t => parseInt(t.stationId) === stationId && dataTypesId.includes(parseInt(t.dataType))).map(t => {
        const stat = stats.find(h => h.typeId === parseInt(t.dataType))
        const unit = stat?.unit ? `${stat?.unit} ` : ''
        return {
            ...t,
            unit,
            gridName: stat?.label || t.dataType,
        }
    }) : []

    const events = stationsEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            return e.graph === '1'
        }
        return false
    })

    const getBarDataEvents = () => events.map(e => ({
        date: e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(),
        value: 1,
        color: getRGBColor(getEventColor(e.eventType)),
        event: e,
    }))

    const eventsFormatted = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        gridName: i18n.events,
        dataList: getBarDataEvents(),
        type: 'bar',
        barWidth: '10px',
    }], [])

    const groupedMeasures = useMemo(() => {
        const measuresFiltered = measures.filter(m => !!m.measures.length)
        return groupBy(measuresFiltered, 'dataType')
    }, [measures])
    const groupedMeasuresKeys = useMemo(() => keys(groupedMeasures), [groupedMeasures])

    const getFilteredData = (data) => data.filter((m, i) => {
        const diffInDays = (m?.date && m[i + 1]?.date) ? getDayDiff(m.date, m[i + 1].date) : 0
        return (m.initialPoint !== 1 && diffInDays <= 31 && !isNil(m?.date)) ? [m] : [
            { ...m, value: undefined, date: moment(m.date).subtract(1, 'second').valueOf() },
            { ...m, marker: 'emptyCircle' },
        ]
    })

    const measuresFormatted = useMemo(() => groupedMeasuresKeys.flatMap((key, index) => {
        const values = groupedMeasures[key]
        return values.map(value => {
            const stat = stats.find(h => h.typeId === value.dataType)
            const unit = stat?.unit ? `${stat?.unit} ` : ''
            return {
                gridIndex: index + 1,
                name: stat.label || '',
                unit,
                color: getColorFromPalettePluvio(index),
                gridName: stat?.label || key,
                dataList: getFilteredData(value.measures),
                type: 'bar',
                connectNulls: false,
                barWidth: getBarWidth(value.measures.length),
            }
        })
    }), [groupedMeasures, groupedMeasuresKeys, stationName, stationCode, stats])

    const dataFormatted = [...eventsFormatted, ...measuresFormatted]

    const eventGrids = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        yOptions: {
            type: 'value',
            nameRotate: 0,
            nameGap: 20,
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        xOptions: {
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        },
        gridOptions: {
            top: 25,
            left: '100px',
            height: 40,
        },
    }], [])

    const measuresGrids = useMemo(() => measuresFormatted.length ? groupedMeasuresKeys.map((key, index) => {
        const dataType = groupedMeasures[key]?.[0]?.dataType
        const stat = stats.find(h => h.typeId === dataType)
        const unit = stat?.unit ? `[${stat?.unit}] ` : ''
        return {
            gridIndex: index + 1,
            name: `${stat?.label || key} ${unit}`,
            gridOptions: {
                left: '100px',
                top: 35,
                height: graphicHeight,
            },
            yOptions: {
                ...setYOptions(null, yAutomaticScaleValues(concat(thresholds.filter(t => t.dataType === `${dataType}`).map(t => t.value), measuresFormatted[index].dataList.map(d => d.value)), 3)),
            },
        }
    }) : [], [graphicHeight, groupedMeasures, groupedMeasuresKeys, measuresFormatted, stats])

    const grids = [...eventGrids, ...measuresGrids]

    const formatTooltip = params => {
        if (keys(params[0].value[2]?.event)?.length) {
            return getFullDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [,, { event }] }) => {
                return `<br/>${marker} ${seriesName}: ${event.comment ? (chunkWithWords(event.comment, 40).replaceAll('\n', '<br />')) : ''}<br />
                ${hasValue(event.ns) ? `${i18n.staticLevelMeasure} : ${event.ns}m<br />` : ''}
                ${hasValue(event.nc) ? `${i18n.sensorInstantLevel} : ${event.nc}m<br />` : ''}`
            }).join('')
        }
        return getFullDate(params[0].axisValue) + params.filter(({ value: [, result] }) => !isNil(result)).map(({ marker, seriesName, value: [, result, statusObj], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, ROUND_VALUE)} ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`).join('')
    }

    return (
        <MultiChart
            data={dataFormatted}
            grids={grids}
            stationsEvents={stationsEvents}
            thresholds={thresholds}
            roundValue={ROUND_VALUE}
            footerHeight={70}
            exportName={stationName || stationCode || ''}
            withDataZoom
            withOtherLegend
            withYZoom
            withToolLine
            withToolMarker
            onFullScreen={onFullScreen}
            defaultDisplayMarker={false}
            tooltipFormatter={formatTooltip}
            defaultMinDate={defaultMinDate}
            defaultMaxDate={defaultMaxDate}
            withExport={authorizeExport(PLUVIO)}
        />
    )
}

PluviometerChart.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(PluviometerDto),
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            code: PropTypes.string,
        }),
    ]),
    stats: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    stationThresholds: PropTypes.array,
    graphicHeight: PropTypes.number,
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
}

const DEFAULT_DISPLAY_COTE = 0
const DEFAULT_DISPLAY_MODES = {
    auto: true,
    brute: false,
    min: false,
    max: false,
    average: false,
}

const PiezoChartPanel = ({
    station,
    stationType,
    graphicHeight = 250,
}) => {
    const idMinJournalier = useApplicationSetting('ID_MIN_JOURNALIER', intParser)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => v === HISTO ? v : (v ? parseInt(v) : J365)) || J365

    const getDefaultMinDate = () => lastSelectedTime === HISTO ? undefined : moment().subtract(lastSelectedTime, 'days').startOf('day').valueOf()
    const getDefaultMaxDate = () => lastSelectedTime === HISTO ? moment().valueOf() : undefined

    const [time, setTime] = useState(lastSelectedTime || J365)
    const [minDate, setMinDate] = useState(getDefaultMinDate())
    const [maxDate, setMaxDate] = useState(getDefaultMaxDate())
    const [displayCote, setDisplayCote] = useLocalStorage(DISPLAY_COTE, DEFAULT_DISPLAY_COTE)
    const [displayModes, setDiplayModes] = useLocalStorage(PIEZO_TAB_DISPLAY_MODES, DEFAULT_DISPLAY_MODES)
    const [measures, setMeasures] = useState([])
    const [fullScreen, setFullScreen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [piezoThresholds, setPiezoThresholds] = useState([])
    const [piezoMeasuresStats, setPiezoMeasuresStats] = useState([])
    const [stationEvents, setStationEvents] = useState([])
    const [piezometryOptions, setPiezometryOptions] = useState()

    const [typesIdWithSeveralDataPerDay, setTypesIdWithSeveralDataPerDay] = useState([])
    const [dataPerDayIsLoaded, setDataPerDayIsLoaded] = useState(false)
    const [dataPerDayProgress, setDataPerDayProgress] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        initController()

        return () => {
            controllerRef.current.abort()

            setTime(J365)
            setMinDate(moment().subtract(J365, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
            setMeasures([])
            setFullScreen(false)
            setDataLoaded(false)

            setPiezoThresholds([])
            setPiezoMeasuresStats([])
            setStationEvents([])
        }
    }, [])

    const { progress, isLoaded } = useProgressDispatch(() => [
        dispatch(FollowAction.fetchPiezometerThresholds(station.id)).then(({ payload }) => setPiezoThresholds((payload || []))),
        dispatch(FollowAction.fetchPiezoMeasuresStats(station.id)).then(({ payload }) => setPiezoMeasuresStats((payload || []))),
        dispatch(FollowAction.fetchStationsEvents({ type: STATION_TYPE_NAME.piezometry, ids: [station.id] })).then(({ payload }) => setStationEvents((payload || []))),
        dispatch(FollowAction.fetchPiezometerChartOptions(station.id)).then(({ payload }) => setPiezometryOptions(payload)),
    ], [])

    const dataTypesId = useMemo(() => uniq(piezoMeasuresStats.map(stat => stat.typeId)), [piezoMeasuresStats])

    useEffect(() => {
        if (isLoaded) {
            dispatch(FollowAction.hasSeveralDataPerDay({
                dataTypesId,
                stationId: station.id,
                progressCallback: setDataPerDayProgress,
            })).then(({ payload }) => {
                setTypesIdWithSeveralDataPerDay(payload)
                setDataPerDayIsLoaded(true)
            })
        }
    }, [station.id, isLoaded, dataTypesId])

    useEffect(() => {
        if (isLoaded && dataPerDayIsLoaded) {
            setMeasures([])
            setDataLoaded(false)
            const defaultGroupMode = time !== HISTO ? ALL : GROUPS_FUNC.MAX
            const inputs = dataTypesId.flatMap(typeId => {
                const isChronicle = typeId === CHRONIC_TYPE
                const isMinJournalier = (!isNil(idMinJournalier) && typeId === idMinJournalier)
                const hasSeveralDataPerDay = typesIdWithSeveralDataPerDay.find(t => t.typeId === typeId)?.hasSeveralDataPerDay
                const defaultInput = {
                    stationId: station.id,
                    dataType: typeId,
                    chartMode: true,
                    startDate: minDate,
                    endDate: maxDate,
                    validOnly: isChronicle,
                    displayCote: (isChronicle || isMinJournalier) ? (displayCote !== DEFAULT_DISPLAY_COTE ? displayCote : MEASURE_COTE.DEPTH) : DEFAULT_DISPLAY_COTE,
                }
                const piezoModes = uniq([
                    displayModes.brute ? GROUPS_FUNC.RAW : (displayModes.auto ? defaultGroupMode : null),
                    displayModes.max ? GROUPS_FUNC.MAX : null,
                    displayModes.min ? GROUPS_FUNC.MIN : null,
                    displayModes.average ? GROUPS_FUNC.AVERAGE : null,
                ].filter(m => !!m))

                if (isChronicle && piezoModes?.length) {
                    return piezoModes.map(pm => ({
                        ...defaultInput,
                        groupFunc: pm,
                    }))
                } else if (isChronicle && hasSeveralDataPerDay) {
                    return (stationType === OBSERVATORY_STATION_TYPE_NAME.catchment) ? [{
                        ...defaultInput,
                        groupFunc: GROUPS_FUNC.MAX,
                    }, {
                        ...defaultInput,
                        groupFunc: GROUPS_FUNC.MIN,
                    }] : [{
                        ...defaultInput,
                        groupFunc: GROUPS_FUNC.MAX,
                    }]
                }
                return [{
                    ...defaultInput,
                    groupFunc: typeId === SAMPLE_TYPE ? 'SUM' : defaultGroupMode,
                }]
            })
            dispatch(FollowAction.loadPiezoChartMeasures(inputs, () => {}, controllerRef.current.signal)).then(data => {
                setMeasures(prev => [...prev, ...data])
                setDataLoaded(true)
            })
        }
    }, [isLoaded, dataPerDayIsLoaded, time, displayCote, displayModes])

    const fullScreenStyle = fullScreen ? {
        position: 'fixed',
        top: '116px',
        left: 0,
        width: '100%',
        height: 'calc(100vh - 116px)',
        zIndex: 9,
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    const chartHeight = graphicHeight || window.innerHeight / 5

    const onChangeDate = (newTime) => {
        if (newTime === HISTO) {
            setMinDate(undefined)
            setMaxDate(moment().valueOf())
        } else {
            setMinDate(moment().subtract(newTime, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
        }
        setTime(newTime)
    }

    return (isLoaded && dataPerDayIsLoaded) ? (
        <Grid container sx={fullScreenStyle}>
            <Grid item xs={12}>
                <ChartTabs
                    time={time}
                    onChangeTime={onChangeDate}
                    statistics={piezoMeasuresStats}
                    stationType={stationType}
                    setDisplayCote={setDisplayCote}
                    setDiplayModes={setDiplayModes}
                    inprogress={!dataLoaded}
                />
            </Grid>
            <Grid item xs={12}>
                {!dataLoaded ? (
                    <ProgressBar indeterminate />
                ) : (
                    <PiezometerChart
                        station={station}
                        stats={piezoMeasuresStats}
                        stationsEvents={stationEvents}
                        measures={measures}
                        stationThresholds={piezoThresholds}
                        typesIdWithSeveralDataPerDay={typesIdWithSeveralDataPerDay}
                        displayCote={displayCote}

                        piezometryOptions={piezometryOptions}

                        onFullScreen={() => setFullScreen(prevFullScreen => !prevFullScreen)}
                        defaultMinDate={minDate}
                        defaultMaxDate={moment().valueOf()}
                        graphicHeight={chartHeight}
                    />
                )}
            </Grid>
        </Grid>
    ) : <ProgressBar progress={(progress + dataPerDayProgress) / 2} />
}

PiezoChartPanel.propTypes = {
    station: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    stationType: PropTypes.string,
    idsHydroDataType: PropTypes.arrayOf(PropTypes.number),
    idsPluvioDataType: PropTypes.arrayOf(PropTypes.number),
    graphicHeight: PropTypes.number,
    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    instIds: PropTypes.arrayOf(PropTypes.number),
    withChroniclesFollowUp: PropTypes.bool,
    setInstallationTypesChart: PropTypes.func,
    setDataTypesChart: PropTypes.func,
    showTitle: PropTypes.bool,
}

const HydroChartPanel = ({
    station,
    graphicHeight = 250,
}) => {
    const {
        hydrometryThresholds,
    } = useSelector(store => ({
        hydrometryThresholds: store.FollowReducer.hydrometryThresholds,
    }), shallowEqual)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => v === HISTO ? v : (v ? parseInt(v) : J365))

    const [time, setTime] = useState(lastSelectedTime || J365)
    const [minDate, setMinDate] = useState(moment().subtract(J365, 'days').startOf('day').valueOf())
    const [maxDate, setMaxDate] = useState()
    const [measures, setMeasures] = useState([])
    const [fullScreen, setFullScreen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [hydroStatistics, setHydroStatistics] = useState([])
    const [stationEvents, setStationEvents] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        initController()

        return () => {
            controllerRef.current.abort()

            setTime(J365)
            setMinDate(moment().subtract(J365, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
            setMeasures([])
            setFullScreen(false)
            setDataLoaded(false)

            setHydroStatistics([])
            setStationEvents([])
        }
    }, [])

    const { progress, isLoaded } = useProgressDispatch(() => {
        const hydroThresholdPromise = !hydrometryThresholds.length ? [dispatch(FollowAction.fetchHydrometricThresholds())] : []
        return [
            ...hydroThresholdPromise,
            dispatch(FollowAction.fetchHydroStatistics(station.id)).then(({ payload }) => setHydroStatistics((payload || []))),
            dispatch(FollowAction.fetchStationsEvents({ type: STATION_TYPE_NAME.hydrometry, ids: [station.id] })).then(({ payload }) => setStationEvents((payload || []))),
        ]
    }, [])

    const allHydroStats = useMemo(() => uniqBy([...getHardHydroDataTypes().map(dt => ({ ...dt, typeId: dt.id })), ...hydroStatistics], 'typeId'), [hydroStatistics])
    const dataTypesId = useMemo(() => allHydroStats.map(stat => stat.typeId), [allHydroStats])

    const allThresholds = useMemo(() => hydrometryThresholds.filter(t => t.stationId === `${station.id}` && dataTypesId.includes(parseInt(t.dataType))), [dataTypesId, hydrometryThresholds, station.id])

    useEffect(() => {
        if (isLoaded) {
            setMeasures([])
            setDataLoaded(false)
            const defaultGroupMode = time !== HISTO ? ALL : GROUPS_FUNC.MAX
            const inputs = dataTypesId.map(typeId => ({
                stationId: station.id,
                dataType: typeId,
                groupFunc: defaultGroupMode,
                chartMode: true,
                startDate: minDate,
                endDate: maxDate,
            }))
            dispatch(FollowAction.loadHydroChronicMeasures(inputs, () => {}, controllerRef.current.signal)).then(data => {
                setMeasures(prev => [...prev, ...data])
                setDataLoaded(true)
            })
        }
    }, [isLoaded, time])

    const fullScreenStyle = fullScreen ? {
        position: 'fixed',
        top: 0,
        left: 0,
        width: `calc(${window.innerWidth}px - 6.2rem)`,
        height: `calc(${window.innerHeight}px - 6rem)`,
        zIndex: 9,
        marginLeft: '5.5rem',
        marginTop: '5.5rem',
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    const chartHeight = graphicHeight || window.innerHeight / 5 // !fullScreen ? graphicHeight : (window.innerHeight / 2.1)

    const onChangeDate = (newTime) => {
        if (newTime === HISTO) {
            setMinDate(undefined)
            setMaxDate(moment().valueOf())
        } else {
            setMinDate(moment().subtract(newTime, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
        }
        setTime(newTime)
    }

    return (isLoaded) ? (
        <Grid container sx={fullScreenStyle}>
            <Grid item xs={12}>
                <ChartTabs
                    time={time}
                    onChangeTime={onChangeDate}
                    inprogress={!dataLoaded}
                />
            </Grid>
            <Grid item xs={12}>
                {!dataLoaded ? (
                    <ProgressBar indeterminate />
                ) : (
                    <HydrometerChart
                        station={station}
                        stats={allHydroStats}
                        stationsEvents={stationEvents}
                        measures={measures}
                        stationThresholds={allThresholds}

                        onFullScreen={() => setFullScreen(prevFullScreen => !prevFullScreen)}
                        defaultMinDate={minDate}
                        defaultMaxDate={moment().valueOf()}
                        graphicHeight={chartHeight}
                    />
                )}
            </Grid>
        </Grid>
    ) : <ProgressBar progress={progress} />
}

HydroChartPanel.propTypes = {
    station: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    idsHydroDataType: PropTypes.arrayOf(PropTypes.number),
    idsPluvioDataType: PropTypes.arrayOf(PropTypes.number),
    graphicHeight: PropTypes.number,
    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    instIds: PropTypes.arrayOf(PropTypes.number),
    withChroniclesFollowUp: PropTypes.bool,
    setInstallationTypesChart: PropTypes.func,
    setDataTypesChart: PropTypes.func,
    showTitle: PropTypes.bool,
}

const PluvioChartPanel = ({
    station,
    graphicHeight = 250,
}) => {
    const {
        pluviometerAllThresholds,
    } = useSelector(store => ({
        pluviometerAllThresholds: store.FollowReducer.pluviometerAllThresholds,
    }), shallowEqual)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => v === HISTO ? v : (v ? parseInt(v) : J365))

    const [time, setTime] = useState(lastSelectedTime || J365)
    const [minDate, setMinDate] = useState(moment().subtract(J365, 'days').startOf('day').valueOf())
    const [maxDate, setMaxDate] = useState()
    const [measures, setMeasures] = useState([])
    const [fullScreen, setFullScreen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [pluviometerStatistics, setPluviometerStatistics] = useState([])
    const [stationEvents, setStationEvents] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        initController()

        return () => {
            controllerRef.current.abort()

            setTime(J365)
            setMinDate(moment().subtract(J365, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
            setMeasures([])
            setFullScreen(false)
        }
    }, [])

    const { progress, isLoaded } = useProgressDispatch(() => {
        const pluvioThresholdPromise = !pluviometerAllThresholds.length ? [dispatch(FollowAction.fetchPluviometerAllThresholds())] : []
        return [
            ...pluvioThresholdPromise,
            dispatch(FollowAction.fetchPluvioStatistics(station.id)).then(({ payload }) => setPluviometerStatistics((payload || []))),
            dispatch(FollowAction.fetchStationsEvents({ type: STATION_TYPE_NAME.pluviometry, ids: [station.id] })).then(({ payload }) => setStationEvents((payload || []))),
        ]
    }, [])

    const dataTypesId = useMemo(() => uniq(pluviometerStatistics.map(stat => stat.typeId)), [pluviometerStatistics])

    const allThresholds = useMemo(() => pluviometerAllThresholds.filter(t => t.stationId === `${station.id}` && dataTypesId.includes(parseInt(t.dataType))), [dataTypesId, pluviometerAllThresholds, station.id])

    useEffect(() => {
        if (isLoaded) {
            setMeasures([])
            setDataLoaded(false)
            const defaultGroupMode = time !== HISTO ? ALL : GROUPS_FUNC.MAX
            const inputs = dataTypesId.map(typeId => ({
                stationId: station.id,
                dataType: typeId,
                groupFunc: [DATA_TYPE.VIRTUAL_EFFECTIVE_RAIN, DATA_TYPE.RAIN, DATA_TYPE.ETP].includes(typeId) ? PLUVIO_GROUPED : defaultGroupMode,
                chartMode: true,
                startDate: minDate,
                endDate: maxDate,
            }))
            dispatch(FollowAction.loadPluvioChronicMeasures(inputs, () => {}, controllerRef.current.signal)).then(data => {
                setMeasures(prev => [...prev, ...data])
                setDataLoaded(true)
            })
        }
    }, [isLoaded, time])

    const fullScreenStyle = fullScreen ? {
        position: 'fixed',
        top: 0,
        left: 0,
        width: `calc(${window.innerWidth}px - 6.2rem)`,
        height: `calc(${window.innerHeight}px - 6rem)`,
        zIndex: 9,
        marginLeft: '5.5rem',
        marginTop: '5.5rem',
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    const chartHeight = graphicHeight || window.innerHeight / 5 // !fullScreen ? graphicHeight : (window.innerHeight / 2.1)

    const onChangeDate = (newTime) => {
        if (newTime === HISTO) {
            setMinDate(undefined)
            setMaxDate(moment().valueOf())
        } else {
            setMinDate(moment().subtract(newTime, 'days').startOf('day').valueOf())
            setMaxDate(undefined)
        }
        setTime(newTime)
    }

    return (isLoaded) ? (
        <Grid container sx={fullScreenStyle}>
            <Grid item xs={12}>
                <ChartTabs
                    time={time}
                    onChangeTime={onChangeDate}
                    inprogress={!dataLoaded}
                />
            </Grid>
            <Grid item xs={12}>
                {!dataLoaded ? (
                    <ProgressBar indeterminate />
                ) : (
                    <PluviometerChart
                        station={station}
                        stats={pluviometerStatistics}
                        stationsEvents={stationEvents}
                        measures={measures}
                        stationThresholds={allThresholds}

                        onFullScreen={() => setFullScreen(prevFullScreen => !prevFullScreen)}
                        defaultMinDate={minDate}
                        defaultMaxDate={moment().valueOf()}
                        graphicHeight={chartHeight}
                    />
                )}
            </Grid>
        </Grid>
    ) : <ProgressBar progress={progress} />
}

PluvioChartPanel.propTypes = {
    station: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    idsHydroDataType: PropTypes.arrayOf(PropTypes.number),
    idsPluvioDataType: PropTypes.arrayOf(PropTypes.number),
    graphicHeight: PropTypes.number,
    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    instIds: PropTypes.arrayOf(PropTypes.number),
    withChroniclesFollowUp: PropTypes.bool,
    setInstallationTypesChart: PropTypes.func,
    setDataTypesChart: PropTypes.func,
    showTitle: PropTypes.bool,
}

const ParameterPanel = ({
    qualitometer,
    thresholds = [],
    additionalData = [],

    graphOptions = {},
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.FollowReducer.parameters,
    }), shallowEqual)

    const dispatch = useDispatch()

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const defaultDraphOptions = useMemo(() => ({
        regroupAxis: true,
        ...graphOptions,
    }), [graphOptions])

    const [analysis, setAnalysis] = useState([])
    const [displayStatistics, setDisplayStatistics] = useState(false)
    const [displayAverages, setDisplayAverages] = useState(false)
    const [nbPointMovingAverage, setNbPointMovingAverage] = useState(1)
    const [displayHype, setDisplayHype] = useState(false)
    const [parameterSelected, setParameterSelected] = useState()

    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean(false)

    useEffect(() => {
        if (isUndefined(qualitometer)) {
            return
        }

        notLoaded()
        setAnalysis([])
        initController()

        const analysisFilter = {
            lightMode: true,
            dataLoaded: true,
            displayAdvancedStatistics: false,
        }

        dispatch(FollowAction.getAnalysis(analysisFilter, [qualitometer.id], loaded)).then(list => setAnalysis(list.map(a => new DtoAnalysisLight(a))))

        return () => {
            controllerRef.current.abort()
            setDisplayStatistics(false)
            setDisplayAverages(false)
            setNbPointMovingAverage(1)
            setDisplayHype(false)
        }
    }, [parameterSelected, qualitometer])

    const parametersCodesByAnalysis = useMemo(() => uniq(analysis.map(a => a.parameter)), [analysis])
    const parametersFiltered = useMemo(() => sortBy(parameters.filter(p => parametersCodesByAnalysis.includes(p.code)), 'name'), [parameters, parametersCodesByAnalysis])
    const analysisFiltered = useMemo(() => analysis.filter(a => a.parameter === parameterSelected), [analysis, parameterSelected])

    const formattedAnalysis = useMemo(() => analysisFiltered.map(a => ({
        ...a,
        ...calculateThresholdResult(a, thresholds),
    })), [analysisFiltered, thresholds])

    const allValues = formattedAnalysis.map(a => a.result)
    const { max: maxY, min } = yAutomaticScaleValues(allValues)

    return isLoaded ? (
        <Grid container item xs={12} sx={{ padding: '0 0.5rem' }}>
            {!!parametersFiltered.length && (
                <Grid item xs={4}>
                    <SimpleMultiAutocomplete
                        value={parameterSelected}
                        onChange={setParameterSelected}
                        options={parametersFiltered}
                        keyValue='code'
                        label={i18n.parameter}
                        disablePortal={false}
                    />
                </Grid>
            )}
            {!!analysisFiltered.length && (
                <Grid item xs={12}>
                    <ParameterGraph
                        analysis={analysisFiltered}
                        additionalData={additionalData}
                        thresholds={thresholds}

                        graphOptions={{
                            ...defaultDraphOptions,
                            maxY: maxY || 0,
                            minY: min < 0 ? min : 0,
                            displayStatistics,
                            displayAverages,
                            nbPointMovingAverage,
                            displayHype,
                        }}

                        componentHeight={(window.innerHeight * 0.365)}
                    />
                </Grid>
            )}
        </Grid>
    ) : <ProgressBar indeterminate />
}

ParameterPanel.propTypes = {
    qualitometer: PropTypes.number,
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    additionalData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        unit: PropTypes.string,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.number,
            value: PropTypes.number,
        })),
    })),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,
    }),
}

const FollowEvents = ({
    station = {},
    stationType,
    piezoObsResults = [],
    hydroObsResults = [],
    pluvioObsResults = [],
    qualitoObsResults = [],
}) => {
    const getEventTypeName = (typeName) => {
        switch (typeName) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometer:
            case STATION_TYPE_NAME.piezometry:
                return STATION_TYPE_NAME.piezometer
            default:
                return typeName
        }
    }

    const eventsCodes = useMemo(() => [{ code: station.id, stationType: getEventTypeName(station.typeName) }], [station.id, station.typeName])

    return (
        <EventsCard
            height='calc(100vh - 430px)'
            codes={eventsCodes}
            stationId={station.id}
            stationType={stationType}
            showCode={false}
            piezoObsResults={piezoObsResults}
            hydroObsResults={hydroObsResults}
            pluvioObsResults={pluvioObsResults}
            qualitoObsResults={qualitoObsResults}
        />
    )
}

FollowEvents.propTypes = {
    station: PropTypes.shape({
        id: PropTypes.number,
        typeName: PropTypes.string,
    }),
    stationType: PropTypes.string,
    piezoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    hydroObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    pluvioObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
    qualitoObsResults: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const Follow = ({
    match: { params: { id, stationType } },
}) => {
    const {
        piezometer,
        hydrologicalStation,
        pluviometer,
        qualitometer,
        piezoObservatoryFollowResults,
        hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults,
        contributors,
        sandreCodes,
        watermasses,
        productionUnits,
        resources,

        accountUserSettingsStatus,

        accountHabilitations,

        piezometerAllThresholds,
        hydrometryThresholds,
        pluviometerAllThresholds,

        piezometryDataTypes,
        hydrometryDataTypes,
        pluviometryDataTypes,
    } = useSelector(store => ({
        piezometer: store.HomeReducer.piezometer,
        hydrologicalStation: store.HomeReducer.hydrologicalStation,
        pluviometer: store.HomeReducer.pluviometer,
        qualitometer: store.HomeReducer.qualitometer,
        piezoObservatoryFollowResults: store.FollowReducer.piezoObservatoryFollowResults,
        hydroObservatoryFollowResults: store.FollowReducer.hydroObservatoryFollowResults,
        pluvioObservatoryFollowResults: store.FollowReducer.pluvioObservatoryFollowResults,
        qualitoObservatoryFollowResults: store.FollowReducer.qualitoObservatoryFollowResults,
        contributors: store.HomeReducer.contributors,
        sandreCodes: store.HomeReducer.sandreCodes,
        watermasses: store.FollowReducer.watermasses,
        productionUnits: store.HomeReducer.productionUnits,
        resources: store.ResourcesReducer.resources,

        accountUserSettingsStatus: store.DataManagerReducer.account.accountUserSettingsStatus,

        accountHabilitations: store.AccountReducer.accountHabilitations,

        piezometerAllThresholds: store.FollowReducer.piezometerAllThresholds,
        hydrometryThresholds: store.FollowReducer.hydrometryThresholds,
        pluviometerAllThresholds: store.FollowReducer.pluviometerAllThresholds,

        piezometryDataTypes: store.FollowReducer.piezometryDataTypes,
        hydrometryDataTypes: store.FollowReducer.hydrometryDataTypes,
        pluviometryDataTypes: store.FollowReducer.pluviometryDataTypes,
    }), shallowEqual)

    const [simpleSheet, setSimpleSheet] = useState(true)
    const [unitLinkedStations, setUnitLinkedStation] = useState([])
    const [pictures, setPictures] = useState([])
    const [documents, setDocuments] = useState([])
    const [obsResults, setObsResults] = useState([])

    const stationId = useMemo(() => parseInt(id), [id])

    const { progress: qualityProgress, isLoaded: qualityIsLoaded } = useStateProgress([accountUserSettingsStatus])

    const dispatch = useDispatch()

    const havePiezoAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PIEZO_MODULE), [accountHabilitations])
    const haveHydroAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_HYDRO_MODULE), [accountHabilitations])
    const havePluvioAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PLUVIO_MODULE), [accountHabilitations])
    const haveQualitoAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_QUALITO_MODULE), [accountHabilitations])
    const haveUnitAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_PRODUCTION_MODULE), [accountHabilitations])
    const haveResourceAccess = useMemo(() => !accountHabilitations.length || componentHasHabilitations(H_RESOURCE_MODULE), [accountHabilitations])

    useEffect(() => {
        switch (stationType) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometry:
                if (!havePiezoAccess) {
                    dispatch(HomeAction.logout())
                    dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
                }
                return
            case STATION_TYPE_NAME.hydrometry:
                if (!haveHydroAccess) {
                    dispatch(HomeAction.logout())
                    dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
                }
                return
            case STATION_TYPE_NAME.pluviometry:
                if (!havePluvioAccess) {
                    dispatch(HomeAction.logout())
                    dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
                }
                return
            case STATION_TYPE_NAME.quality:
                if (!haveQualitoAccess) {
                    dispatch(HomeAction.logout())
                    dispatch(ToastrAction.error(i18n.AccessRightDeny, true))
                }
                return
            default:
                return
        }
    }, [haveHydroAccess, havePiezoAccess, havePluvioAccess, haveQualitoAccess, stationType])

    useEffect(() => {
        if (stationType === OBSERVATORY_STATION_TYPE_NAME.catchment || stationType === STATION_TYPE_NAME.piezometry) {
            dispatch(HomeAction.fetchPiezometer(stationId))
            if (!piezometerAllThresholds.length) {
                dispatch(FollowAction.fetchPiezometerAllThresholds())
            }
            if (!piezometryDataTypes.length) {
                dispatch(FollowAction.fetchPiezometryDataTypes())
            }
            if (!piezoObservatoryFollowResults.length) {
                dispatch(FollowAction.fetchSpecificPiezoObservatoryFollowResult([stationId])).then(result => setObsResults(result))
            } else {
                const result = [piezoObservatoryFollowResults.find(ofr => ofr.id === stationId)] || []
                setObsResults(result)
            }
        } else if (stationType === STATION_TYPE_NAME.hydrometry) {
            dispatch(HomeAction.fetchHydrologicalStation(stationId))
            if (!hydrometryThresholds.length) {
                dispatch(FollowAction.fetchHydrometricThresholds())
            }
            if (!hydrometryDataTypes.length) {
                dispatch(FollowAction.fetchHydrometryDataTypes())
            }
            if (!hydroObservatoryFollowResults.length) {
                dispatch(FollowAction.fetchSpecificHydroObservatoryFollowResult([stationId])).then(result => setObsResults(result))
            } else {
                const result = [hydroObservatoryFollowResults.find(ofr => ofr.id === stationId)] || []
                setObsResults(result)
            }
        } else if (stationType === STATION_TYPE_NAME.pluviometry) {
            dispatch(HomeAction.fetchPluviometer(stationId))
            if (!pluviometerAllThresholds.length) {
                dispatch(FollowAction.fetchPluviometerAllThresholds())
            }
            if (!pluviometryDataTypes.length) {
                dispatch(FollowAction.fetchPluviometryDataTypes())
            }
            if (!pluvioObservatoryFollowResults.length) {
                dispatch(FollowAction.fetchSpecificPluvioObservatoryFollowResult([stationId])).then(result => setObsResults(result))
            } else {
                const result = [pluvioObservatoryFollowResults.find(ofr => ofr.id === stationId)] || []
                setObsResults(result)
            }
        } else if (stationType === STATION_TYPE_NAME.quality) {
            dispatch(HomeAction.fetchQualitometer(stationId))
            if (!qualitoObservatoryFollowResults.length) {
                dispatch(FollowAction.fetchSpecificQualitoObservatoryFollowResult([stationId])).then(result => setObsResults(result))
            } else {
                const result = [qualitoObservatoryFollowResults.find(ofr => ofr.id === stationId)] || []
                setObsResults(result)
            }
            dispatch(FollowAction.fetchParameters())
            dispatch(FollowAction.fetchUnits())
        }

        if (!watermasses.length) {
            dispatch(FollowAction.fetchWatermasses())
        }
        if (!productionUnits.length) {
            dispatch(HomeAction.fetchProductionUnits())
        }
        if (!resources.length) {
            dispatch(ResourcesAction.fetchResources())
        }
        return () => {
            dispatch(HomeActionConstant.resetFollow())
            dispatch(HomeActionConstant.resetLinkedStations())

            setSimpleSheet(true)
            setUnitLinkedStation([])
            setPictures([])
            setDocuments([])
            setObsResults([])
        }
    }, [])

    const { ofr, station } = useMemo(() => {
        switch (stationType) {
            case OBSERVATORY_STATION_TYPE_NAME.catchment:
            case STATION_TYPE_NAME.piezometry:
                return { ofr: obsResults.find(o => o.id === stationId) || {}, station: piezometer }
            case STATION_TYPE_NAME.hydrometry:
                return { ofr: obsResults.find(o => o.id === stationId) || {}, station: hydrologicalStation }
            case STATION_TYPE_NAME.pluviometry:
                return { ofr: obsResults.find(o => o.id === stationId) || {}, station: pluviometer }
            case STATION_TYPE_NAME.quality:
                return { ofr: obsResults.find(o => o.id === stationId) || {}, station: qualitometer }
            default:
                return { ofr: {}, station: {} }
        }
    }, [obsResults, hydrologicalStation, piezometer, pluviometer, qualitometer, stationId, stationType])

    useEffect(() => {
        if (!isNil(station?.code)) {
            dispatch(HomeAction.fetchLinkedStations([station.code], getStationTypeCodeFromType(stationType))).then(result => {
                const formattedResult = result.flatMap(s => new DtoAssociatedStation(s))
                const linkedInst = formattedResult.filter(s => s.typeName === STATION_TYPE_NAME.installation).map(s => ({ code: s.stationLinkedCode, stationType: s.typeName }))
                const st = stationType === OBSERVATORY_STATION_TYPE_NAME.catchment ? STATION_TYPE_NAME.piezometry : stationType
                const linkedStations = [{ code: station.code, stationType: st }, ...linkedInst]
                dispatch(HomeAction.fetchLinkedPictures(linkedStations)).then(setPictures)
                dispatch(HomeAction.fetchLinkedDocuments(linkedStations)).then(docs => setDocuments(docs.filter((d) => !d.name.endsWith('.kml') && !d.name.endsWith('.KML'))))
            })
        }
    }, [station])

    useTitle(() => [{
        title: i18n.follows,
        href: '/follows',
    }, {
        title: ofr?.name || station?.code || ofr.city || stationId,
        href: `/follows/${stationType}/${stationId}`,
    }], [ofr, station])

    const operator = useMemo(() => {
        const operatorsFiltered = uniqBy(station?.link_contributors || [], 'idContributor')?.map(c => {
            const contributor = contributors.find(cs => cs.id === c.idContributor)
            const type = getSandreLabel(sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType)
            return type === OPERATOR_TYPE_NAME ? contributor : undefined
        }).filter(c => !!c) || []
        const operatorsSorted = sortBy(operatorsFiltered, 'startDate')
        return !isNil(operatorsSorted[0]) ? (operatorsSorted[0]?.mnemonique || operatorsSorted[0]?.name || '') : undefined
    }, [contributors, sandreCodes, station?.link_contributors])

    const owner = useMemo(() => {
        const ownersFiltered = uniqBy(station?.link_contributors || [], 'idContributor')?.map(c => {
            const contributor = contributors.find(cs => cs.id === c.idContributor)
            const type = getSandreLabel(sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType)
            return type === OWNER_TYPE_NAME ? contributor : undefined
        }).filter(c => !!c) || []
        const ownersSorted = sortBy(ownersFiltered, 'startDate')
        return !isNil(ownersSorted[0]) ? (ownersSorted[0]?.mnemonique || ownersSorted[0]?.name || '') : undefined
    }, [contributors, sandreCodes, station?.link_contributors])

    const watermassesFormatted = useMemo(() => station?.link_watermasses?.map(w => `${w.watermassCode} - ${watermasses.find(wa => w.watermassCode === wa.code)?.name || ''}`).join(', '), [station?.link_watermasses, watermasses])

    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))

    const productionUnit = useMemo(() => haveUnitAccess ? productionUnits.find(p => p.name === ofr.productionUnit) || {} : {}, [haveUnitAccess, ofr.productionUnit, productionUnits])
    const resource = useMemo(() => haveResourceAccess ? resources.find(p => p.name === ofr.resource) || {} : {}, [haveResourceAccess, ofr.resource, resources])

    useEffect(() => {
        if (keys(productionUnit).length) {
            dispatch(HomeAction.fetchLinkedStation(productionUnit.code, STATION_TYPE_CONSTANT.productionUnit)).then(d => setUnitLinkedStation(filterObsLinkedStations(d)))
        }
    }, [productionUnit])

    const getInCrisisTag = allTags => {
        const inCrisisTag = allTags.filter(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || d.value?.includes('alerte'))
        return inCrisisTag.length ? inCrisisTag.map(tag => ({ ...tag, color: ['red', 'indianred', 'darkmagenta'].includes(tag.color) ? tag.color : 'indianred' })) : []
    }

    const getOnAlertTag = allTags => {
        const alertTag = allTags.filter(d => d.value?.includes('vigilance'))
        return alertTag.length ? alertTag.map(tag => ({ ...tag, color: 'orange' })) : []
    }

    const unitColor = useMemo(() => {
        const datas = unitLinkedStations.flatMap(l => {
            const findedOfr = obsResults.find(o => o.id === l.stationLinkedId)
            return findedOfr?.data?.map(d => ({ ...d, stationName: l.stationLinkedName || l.stationLinkedCode })) || []
        })
        const tagsInCrisis = getInCrisisTag(datas)
        const tagsOnAlert = tagsInCrisis.length ? tagsInCrisis : getOnAlertTag(datas)
        const monitoringTags = tagsOnAlert.length ? tagsOnAlert : datas.filter(d => ['green', 'lightgreen'].includes(d.color))
        const tagsNoData = monitoringTags.length ? monitoringTags : datas.filter(d => ['grey', 'gray'].includes(d.color))
        return tagsNoData[0]?.color
    }, [unitLinkedStations, obsResults.length])

    const unitImg = getMarkerByStationType(STATION_TYPE_NAME.productionUnit, unitColor)

    const mapAndEventsPanel = (
        <>
            <LittleMap points={[station]} />
            <FollowEvents
                station={station}
                stationType={stationType}
                piezoObsResults={(stationType === OBSERVATORY_STATION_TYPE_NAME.catchment || stationType === STATION_TYPE_NAME.piezometry) && obsResults}
                hydroObsResults={stationType === STATION_TYPE_NAME.hydrometry && obsResults}
                pluvioObsResults={stationType === STATION_TYPE_NAME.pluviometry && obsResults}
                qualitoObsResults={stationType === STATION_TYPE_NAME.quality && obsResults}
            />
        </>
    )

    return (
        <Grid container sx={{ position: 'absolute' }}>
            <Grid container item xs={12} sx={{ backgroundColor: mainGrey, minHeight: 50, padding: mdMatches ? '1rem 3rem 0.7rem' : '1rem 3rem 0.7rem', borderTop: `solid 1px ${mainBlack}` }}>
                <Option
                    first
                    xs='auto'
                    sx={{ padding: '0 2rem' }}
                    selected={simpleSheet}
                    label={i18n.simpleCard}
                    onClick={() => setSimpleSheet(true)}
                />
                <Option
                    xs='auto'
                    sx={{ padding: '0 2rem' }}
                    selected={!simpleSheet}
                    label={i18n.detailsCard}
                    onClick={() => setSimpleSheet(false)}
                />
            </Grid>
            {keys(station).length ? (
                <Grid container item xs={12} justifyContent='space-between' sx={{ padding: mdMatches ? '10px 50px 10px 42px' : '10px 30px' }}>
                    <Grid container item md={8.5} xs={12} sx={{ maxHeight: '250px' }}>
                        <Grid container item xs={12} sx={{ borderBottom: !!simpleSheet && '1px solid rgba(233, 233, 233, 0.8)', padding: '0.5em 0 0.5em 0.5em' }}>
                            <Grid container direction='column' justifyContent='flex-start' item xs={ofr.data?.length ? 9 : 12}>
                                <Grid container sx={{ margin: '5px 0' }}>
                                    {ofr.city && (
                                        <Grid item sx={{ fontWeight: 'bold', fontSize: 20 }}>
                                            <span>{ofr.city || ''}</span>
                                        </Grid>
                                    )}
                                    {(ofr.name || station.name) && (
                                        <>
                                            <Grid item sx={{ fontWeight: 'bold', fontSize: 20, margin: '0 15px' }}>
                                                <span>-</span>
                                            </Grid>
                                            <Grid item sx={{ fontWeight: 'bold', fontSize: 20 }}>
                                                <span>{ofr.name || station.name}</span>
                                            </Grid>
                                        </>
                                    )}
                                    {(ofr.code || station.code) && (
                                        <>
                                            <Grid item sx={{ fontWeight: 'bold', fontSize: 20, margin: '0 15px' }}>
                                                <span>-</span>
                                            </Grid>
                                            <Grid item sx={{ fontWeight: 'bold', fontSize: 20 }}>
                                                <span>{ofr.code || station.code}</span>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                {!isNil(operator) && (
                                    <Grid sx={{ margin: '5px 0' }}>
                                        <span>{i18n.operator}: {operator}</span>
                                    </Grid>
                                )}
                                {!isNil(owner) && (
                                    <Grid sx={{ margin: '5px 0' }}>
                                        <span>{i18n.owner}: {owner}</span>
                                    </Grid>
                                )}
                                {(haveUnitAccess && ofr.productionUnit) && (
                                    <Grid container alignItems='center' sx={{ cursor: productionUnit.id && 'pointer', margin: '5px 0' }} onClick={() => productionUnit.id ? dispatch(push(`/productions/${productionUnit.id}`)) : {}}>
                                        {!!unitImg && (
                                            <Grid item>
                                                <img src={unitImg} alt={STATION_TYPE_NAME.productionUnit} style={{ height: '25px' }} />
                                            </Grid>
                                        )}
                                        <Grid item sx={{ marginLeft: '5px' }}>
                                            <span>{i18n.unit}: {ofr.productionUnit || ''}</span>
                                        </Grid>
                                    </Grid>
                                )}
                                {(ofr.aquifer || ofr.nature || ofr.fieldMode) && (
                                    <Grid container sx={{ margin: '5px 0' }}>
                                        {(haveResourceAccess && ofr.aquifer) && (
                                            <Grid item sx={{ cursor: resource.id && 'pointer' }} onClick={() => resource.id ? dispatch(push(`/resources/${resource.id}`)) : {}}>
                                                <span>{i18n.resource}: {ofr.aquifer || ''}</span>
                                            </Grid>
                                        )}
                                        {ofr.nature && (
                                            <>
                                                {!!ofr.aquifer && (
                                                    <Grid item sx={{ marginLeft: '5px' }}>
                                                        <span>-</span>
                                                    </Grid>
                                                )}
                                                <Grid item sx={{ marginLeft: '5px' }}>
                                                    <span>{i18n.nature}: {ofr.nature}</span>
                                                </Grid>
                                            </>
                                        )}
                                        {ofr.fieldMode && (
                                            <>
                                                {!!ofr.nature && (
                                                    <Grid item sx={{ marginLeft: '5px' }}>
                                                        <span>-</span>
                                                    </Grid>
                                                )}
                                                <Grid item sx={{ marginLeft: '5px' }}>
                                                    <span>{i18n.fieldMode}: {ofr.fieldMode}</span>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container item xs={ofr.data?.length ? 3 : 0}>
                                {!!ofr.data?.length && ofr.data.map(ofd => (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            backgroundColor: ofd.color,
                                            padding: '3px',
                                            marginBottom: '3px',
                                            borderRadius: SMALL_RADIUS,
                                            textAlign: 'center',
                                            color: getTextColorByBackground(ofd.color),
                                            border: (ofd.color === 'white' || ofd.color === '#ffffff') && `solid 1px ${mainBlack}`,
                                            fontSize: '10.5px',
                                            lineHeight: 1.5,
                                            height: 'fit-content',
                                        }}
                                    >
                                        <span>{ofd.value}</span>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {!!pictures.length && (
                            <SmallPicturePanel pictures={pictures} />
                        )}
                        {!!documents.length && (
                            <FilePanel files={documents} hideTitle />
                        )}
                        {!simpleSheet && !!keys(station).length && (
                            <Grid container item xs={12} sx={{ border: `1px solid ${mainBlack}`, padding: '0.75rem', borderRadius: SMALL_RADIUS }}>
                                <Grid container item xs={12}>
                                    <Grid container item xs={6} rowGap={0.5} alignContent='flex-start'>
                                        <Grid item xs={12} sx={{ paddingBottom: '0.25rem', '& h4': { margin: 0 } }}>
                                            <h4>{i18n.descriptif} :</h4>
                                        </Grid>
                                        <Grid container item xs={12} alignItems='center' gap={1}>
                                            <Grid item xs='auto'>
                                                <span>{i18n.creationDate}:</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span>{getDate(station.creationDate)}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} alignItems='center' gap={1}>
                                            <Grid item xs='auto'>
                                                <span>{i18n.closeDate}:</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span>{getDate(station.closeDate)}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} alignItems='center' gap={1}>
                                            <Grid item xs='auto'>
                                                <span>{i18n.watermasses}:</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span>{watermassesFormatted}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6} rowGap={0.5} alignContent='flex-start'>
                                        <Grid item xs={12} sx={{ paddingBottom: '0.25rem', '& h4': { margin: 0 } }}>
                                            <h4>{i18n.landmarks} :</h4>
                                        </Grid>
                                        {!!hasValue(station.altitude) && (
                                            <Grid container item xs={12} alignItems='center' gap={1}>
                                                <Grid item xs='auto'>
                                                    <span>{i18n.height}:</span>
                                                </Grid>
                                                <Grid item xs>
                                                    <span>{station.link_altimetrySystems[station.link_altimetrySystems.length - 1]?.altitude}</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!!hasValue(station.cote) && (
                                            <Grid container item xs={12} alignItems='center' gap={1}>
                                                <Grid item xs='auto'>
                                                    <span>{i18n.rating}:</span>
                                                </Grid>
                                                <Grid item xs>
                                                    <span>{station.cote}</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sx={{ paddingTop: '0.75rem', paddingBottom: '0.25rem', '& h4': { margin: 0 } }}>
                                            <h4>{i18n.coordinates} :</h4>
                                        </Grid>
                                        {!!hasValue(station.x) && (
                                            <Grid container item xs={12} alignItems='center' gap={1}>
                                                <Grid item xs='auto'>
                                                    <span>X:</span>
                                                </Grid>
                                                <Grid item xs>
                                                    <span>{station.x}</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!!hasValue(station.y) && (
                                            <Grid container item xs={12} alignItems='center' gap={1}>
                                                <Grid item xs='auto'>
                                                    <span>Y:</span>
                                                </Grid>
                                                <Grid item xs>
                                                    <span>{station.y}</span>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid container item xs={12} alignItems='center' gap={1} sx={{ paddingTop: '0.75rem' }}>
                                        <Grid item xs='auto' sx={{ paddingBottom: '0.25rem', '& h4': { margin: 0 } }}>
                                            <h4>{i18n.comment} :</h4>
                                        </Grid>
                                        {!isNil(station.comment) && (
                                            <Grid item xs sx={{ '& span': { wordWrap: 'break-word' } }}>
                                                <span>{station.comment}</span>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container item xs={12}>
                            {(stationType === OBSERVATORY_STATION_TYPE_NAME.catchment || stationType === STATION_TYPE_NAME.piezometry) && <PiezoChartPanel station={station} stationType={stationType} />}
                            {(stationType === STATION_TYPE_NAME.hydrometry) && <HydroChartPanel station={station} />}
                            {(stationType === STATION_TYPE_NAME.pluviometry) && <PluvioChartPanel station={station} />}
                            {(stationType === STATION_TYPE_NAME.quality) && (qualityIsLoaded ? (
                                <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <SituationHydrobioPanel qualitometer={station} />
                                    </Grid>
                                    <Grid container item xs={12} sx={{ padding: '0.5em' }}>
                                        <Grid item xs={12}>
                                            <ParameterPanel
                                                qualitometer={qualitometer}
                                                thresholds={[]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : <ProgressBar progress={qualityProgress} style={{ width: '100%' }} />)}
                        </Grid>
                        {stationType === OBSERVATORY_STATION_TYPE_NAME.catchment && (
                            <Grid container item xs={12}>
                                <InstallationLinkedProduction station={station} />
                            </Grid>
                        )}
                        {!mdMatches && <Grid item xs={12}>{mapAndEventsPanel}</Grid>}
                    </Grid>
                    {mdMatches && (
                        <Grid container item md={3.35} xs={12}>
                            <Grid item xs={12} sx={{ padding: '0 0 0.5em 0', boxShadow: '0px 0px 18px 0px rgb(0 0 0 / 12%)' }}>
                                {mapAndEventsPanel}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            ) : <ProgressBar indeterminate style={{ width: '80%' }} />}
        </Grid>
    )
}

Follow.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            stationType: PropTypes.string,
        }),
    }),
}

export default Follow
